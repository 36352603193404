import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import BlogView from './blog-view'

const BlogController = ({ slug }) => {
  const [loading, setLoading] = useState(false)
  const [blog, setBlog] = useState([])
  const { i18n } = useTranslation()
  const getBlog = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'blog',
      params: {
        slug,
        locale: i18n.language,
      },
    })
      .then((resp) => {
        setBlog(resp.data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getBlog()
  }, [])

  useEffect(() => {
    getBlog()
  }, [slug])

  const viewProps = {
    data: blog,
    loading,
  }
  return <BlogView {...viewProps} />
}

export default BlogController
